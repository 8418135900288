import styled from "@emotion/styled";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Button, DOMAIN, ExternalLink, Image, Tabs, useTheme } from "@oxfun/uikit";
import { useHome } from "./context";
import { useRequest, useSize } from "ahooks";
import { getHomePage } from "services/http";
import { formatToPercentageByThousands, formatToThousands } from "@oxfun/sdk";
import BigNumber from "bignumber.js";
import Empty from "assets/svgs/history-empty.svg";
const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 96px;
  position: relative;
  @media (max-width: 800px) {
    margin-bottom: 60px;
  }
`;
const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1232px) {
    padding: 0 16px;
  }
`;
const VolumeBox = styled.div`
  border: 1px solid ${({ theme }) => theme.system.gray[2]};
  backdrop-filter: blur(20px);
  padding: 28px;
  display: flex;
  align-items: center;
  @media (max-width: 700px) {
    flex-direction: column;
    gap: 24px;
  }
`;
const Volume24h = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > div:first-of-type {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px;
    color: ${({ theme }) => theme.system.primary[5]};
  }
  & > div:last-of-type {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${({ theme }) => theme.system.gray[6]};
    margin-top: 4px;
  }
`;
const Solid = styled.div`
  height: 72px;
  width: 1px;
  background: ${({ theme }) => theme.system.gray[2]};
  @media (max-width: 700px) {
    height: 1px;
    width: 100%;
  }
`;
const PopularBox = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 64px;
  position: relative;
  @media (max-width: 680px) {
    flex-direction: column;
  }
`;
const PopularItem = styled.div`
  padding: 24px;
  max-width: 320px;
  min-height: 500px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.system.gray[2]};
  background: rgba(7, 9, 16, 0.2);
  backdrop-filter: blur(15px);
  z-index: 2;
  @media (max-width: 680px) {
    max-width: 100%;
  }
  &[data-ismobile="true"] {
    border: none;
    max-width: 100%;
    padding: 0;
    padding-top: 24px;
  }
`;
const PopularItemTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.system.gray[9]};
  &[data-ismobile="true"] {
    display: none;
  }
`;
const TrBox = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.system.gray[5]};
  white-space: nowrap;
  margin-top: 8px;

  & > div:nth-of-type(1) {
    flex: 1;
  }
  & > div:nth-of-type(2),
  & > div:nth-of-type(3) {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`;
const TdBox = styled.div`
  display: flex;
  flex-direction: column;
`;
const Td = styled.a`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.system.gray[9]};
  padding: 12px 0;
  & > div:nth-of-type(1) {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  & > div:nth-of-type(2),
  & > div:nth-of-type(3) {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  & > div:nth-of-type(3) {
    color: ${({ theme }) => theme.system.green[5]};
  }
`;
const Bgoverlay = styled.div`
  width: 100%;
  height: 700px;
  width: 980px;
  background: url("/images/overlay-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: -63px;
  left: -50px;
`;
const BgTrade = styled.article`
  width: 100%;
  height: 560px;
  width: 882px;
  background: url("/images/homepage-trade.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transform: skew(-10deg);
  cursor: pointer;
  &:hover {
    transform: skew(0deg);
    box-shadow: 0px 0px 40px 0px rgba(124, 251, 149, 0.5);
    transition: transform 0.3s ease-in-out;
  }
`;
const BgBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
  width: 940px;
  position: absolute;
  left: 650px;
  top: -100px;
  z-index: 1;
  @media (max-width: 700px) {
    display: none;
  }
`;
const ColBox = styled.div`
  width: 160px;
  height: 160px;
  flex-shrink: 0;
  border-radius: 300px;
  background: #7cfb95;
  filter: blur(130px);
  position: absolute;
  left: 58%;
  top: 40%;
  pointer-events: none;
  z-index: 2;
`;
const TabBox = styled.div`
  border: 1px solid ${({ theme }) => theme.system.gray[2]};
  margin-top: 24px;
  padding: 24px;
`;
const EmptyBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.system.gray[6]};
  margin-top: 100px;
`;
const PopularMarkets = memo(() => {
  const intl = useIntl();
  const { iconList, markets } = useHome();
  const theme = useTheme();
  const ref = useRef(null);
  const size = useSize(ref);
  const [activeTab, setActiveTab] = useState("1");
  const data = [
    {
      value: 1,
      children: "Popular Markets"
    },
    {
      value: 2,
      children: "New Listing"
    }
  ];
  const { data: renderDara } = useRequest(getHomePage);
  const PopularMarketsList = useCallback(
    (list: IPolopularMarkets[]) => {
      const payload: IPolopularMarkets[] = list || [];
      const res: IPolopularMarkets[] = [];
      for (const i in payload) {
        const spread24h = new BigNumber(payload?.[i].markPrice).minus(payload[i].open24h);
        const change = spread24h.dividedBy(payload[i].open24h);
        res.push({
          ...payload[i],
          spread24h: !spread24h.isFinite() ? "0" : spread24h.toString(),
          change24h: !change.isFinite() ? "0" : change.toFixed(4)
        });
      }
      return res.length ? (
        res?.map((i, index) => {
          return (
            <Td key={index} href={DOMAIN?.UI + "/markets/" + i.marketName.replace("/USD Perp", "-USD-SWAP-LIN")}>
              <div>
                <Image size={24} src={iconList?.[i.base]} alt="" /> {i.base}{" "}
              </div>
              <div>{formatToThousands(i.indexPrice)}</div>
              <div>
                {" "}
                {i?.spread24h ? (
                  <span
                    {...(theme.themeMode === "dark"
                      ? {
                          style: !new BigNumber(i?.spread24h || 0).isZero()
                            ? {
                                color: new BigNumber(i?.spread24h || 0).isGreaterThan(0)
                                  ? theme.system.green[5]
                                  : theme.system.red[5]
                              }
                            : {}
                        }
                      : {})}
                  >
                    {formatToPercentageByThousands(
                      new BigNumber(i?.spread24h || 0).dividedBy(i?.open24h || 0),
                      2,
                      true
                    )}
                  </span>
                ) : (
                  <span>--</span>
                )}
              </div>
            </Td>
          );
        })
      ) : (
        <EmptyBox>
          <Empty />
          <span>{intl.formatMessage({ defaultMessage: "No Data" })}</span>
        </EmptyBox>
      );
    },
    [renderDara]
  );
  return (
    <Wrapper ref={ref}>
      <ColBox />
      <Container>
        <VolumeBox>
          <Volume24h>
            <div>${formatToThousands(renderDara?.data?.volume24h)}</div>
            <div>{intl.formatMessage({ defaultMessage: "24h Volume" })}</div>
          </Volume24h>
          <Solid></Solid>
          <Volume24h>
            {" "}
            <div>${formatToThousands(renderDara?.data?.openInterest)}</div>
            <div>{intl.formatMessage({ defaultMessage: "Open Interest" })}</div>
          </Volume24h>
        </VolumeBox>
        {(size?.width || 0) > 700 ? (
          <PopularBox>
            <PopularItem>
              <PopularItemTitle>{intl.formatMessage({ defaultMessage: "Popular Markets" })}</PopularItemTitle>
              <TrBox>
                <div>{intl.formatMessage({ defaultMessage: "Trading Pair" })}</div>
                <div>{intl.formatMessage({ defaultMessage: "Price" })}</div>
                <div>{intl.formatMessage({ defaultMessage: "24h Change" })}</div>
              </TrBox>
              <TdBox>{PopularMarketsList(renderDara?.data?.popularMarketList || [])}</TdBox>
            </PopularItem>
            <PopularItem>
              <PopularItemTitle>{intl.formatMessage({ defaultMessage: "New Listing" })}</PopularItemTitle>
              <TrBox>
                <div>{intl.formatMessage({ defaultMessage: "Trading Pair" })}</div>
                <div>{intl.formatMessage({ defaultMessage: "Price" })}</div>
                <div>{intl.formatMessage({ defaultMessage: "24h Change" })}</div>
              </TrBox>
              <TdBox>{PopularMarketsList(renderDara?.data?.newListingMarketList || [])}</TdBox>
            </PopularItem>
            <BgBox>
              <BgTrade onClick={() => window.open(DOMAIN?.UI + "/markets", "_self")}>
                <Bgoverlay></Bgoverlay>
              </BgTrade>
            </BgBox>
          </PopularBox>
        ) : (
          <TabBox>
            <Tabs
              onChange={(e) => {
                setActiveTab(e);
              }}
              activeKey={activeTab}
            >
              {data.map((p) => (
                <Tabs.TabPane key={p.value.toString()} value={p.value.toString()} tab={p.children} />
              ))}
            </Tabs>
            {activeTab === "1" ? (
              <PopularItem data-ismobile={true}>
                <PopularItemTitle data-ismobile={true}>
                  {intl.formatMessage({ defaultMessage: "Popular Markets" })}
                </PopularItemTitle>
                <TrBox>
                  <div>{intl.formatMessage({ defaultMessage: "Trading Pair" })}</div>
                  <div>{intl.formatMessage({ defaultMessage: "Price" })}</div>
                  <div>{intl.formatMessage({ defaultMessage: "24h Change" })}</div>
                </TrBox>
                <TdBox>{PopularMarketsList(renderDara?.data?.popularMarketList || [])}</TdBox>
              </PopularItem>
            ) : (
              <PopularItem data-ismobile={true}>
                <PopularItemTitle data-ismobile={true}>
                  {intl.formatMessage({ defaultMessage: "New Listing" })}
                </PopularItemTitle>
                <TrBox>
                  <div>{intl.formatMessage({ defaultMessage: "Trading Pair" })}</div>
                  <div>{intl.formatMessage({ defaultMessage: "Price" })}</div>
                  <div>{intl.formatMessage({ defaultMessage: "24h Change" })}</div>
                </TrBox>
                <TdBox>{PopularMarketsList(renderDara?.data?.newListingMarketList || [])}</TdBox>
              </PopularItem>
            )}
          </TabBox>
        )}
      </Container>
    </Wrapper>
  );
});
export default PopularMarkets;
