import styled from "@emotion/styled";
import { memo } from "react";
import { useIntl } from "react-intl";
import { Button, DOMAIN, ExternalLink, Image } from "@oxfun/uikit";
import { useHome } from "./context";
const Wrapper = styled.div`
  width: 100%;
  padding: 80px 0;
  background: url("/images/banner_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position-y: -120px;

  @media (max-width: 1680px) {
    background-position-y: -84px;
  }
  @media (max-width: 1480px) {
    background-position-y: 0;
  }
  @media (max-width: 1075px) {
    background-position-x: 43%;
  }
  @media (max-width: 600px) {
    background-position-x: 50%;
  }
`;
const Title = styled.div`
  font-size: 64px;
  font-weight: 700;
  line-height: normal;
  color: ${({ theme }) => theme.system.gray[9]};
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 48px;
  }
  @media (max-width: 1075px) {
    font-size: 40px;
    position: 0 16px;
  }
`;
const CoinBox = styled.div`
  margin-top: 24px;
  display: flex;
`;
const CoinList = styled.div`
  display: flex;
  align-items: center;
  /* gap: 24px; */
  animation: scroll 20s linear infinite;
  &[data-reverse="true"] {
    animation: scroll-reverse 20s linear infinite;
  }
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  @keyframes scroll-reverse {
    0% {
      transform: translateX(-50%);
    }
    100% {
      transform: translateX(0%);
    }
  }
`;

const BtnBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 44px;
  & > button > a {
    color: ${({ theme }) => theme.system.black};
  }
`;
const ButtonContrainer = styled.a`
  width: 180px;
  height: 56px;
  background: ${({ theme }) => theme.system.primary[5]};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.system.black};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  &:hover {
    opacity: 0.8;
  }
  &::after {
    content: "";
    width: 30px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transform: skewX(-20deg);
    background-color: rgba(255, 255, 255, 0.7);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 200% 200%;
    animation: moveAngledStripe 1s linear infinite;
  }
  @keyframes moveAngledStripe {
    0% {
      left: -10px;
    }
    100% {
      left: 100%;
    }
  }
`;
const ButtonAmin = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.system.black};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;
const ColBox = styled.div`
  width: 300px;
  height: 300px;
  flex-shrink: 0;
  border-radius: 300px;
  background: #7cfb95;
  filter: blur(200px);
  position: absolute;
  left: 38%;
  bottom: 40px;
  pointer-events: none;
  z-index: 2;
`;
const Descr = styled.div`
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  color: ${({ theme }) => theme.system.gray[9]};
  text-align: center;
  margin-top: 8px;
`;
const TradeEverying = memo(() => {
  const intl = useIntl();
  const { iconList, markets } = useHome();
  return (
    <Wrapper>
      <ColBox />
      <Title>{intl.formatMessage({ defaultMessage: "Deposit Anything to Trade Everything" })}</Title>
      <Descr>{intl.formatMessage({ defaultMessage: "Up to 50X Leverage on Majors and Memes" })}</Descr>
      <CoinBox>
        <CoinList>
          <img src="/images/coin-list1.png" alt="" />
          <img src="/images/coin-list1.png" alt="" />
        </CoinList>
        <CoinList>
          <img src="/images/coin-list1.png" alt="" />
          <img src="/images/coin-list1.png" alt="" />
        </CoinList>
      </CoinBox>
      <CoinBox>
        <CoinList data-reverse>
          <img src="/images/coin-list2.png" alt="" />
          <img src="/images/coin-list2.png" alt="" />
        </CoinList>
        <CoinList data-reverse>
          <img src="/images/coin-list2.png" alt="" />
          <img src="/images/coin-list2.png" alt="" />
        </CoinList>
      </CoinBox>
      <CoinBox>
        <CoinList>
          <img src="/images/coin-list3.png" alt="" />
          <img src="/images/coin-list3.png" alt="" />
        </CoinList>
        <CoinList>
          <img src="/images/coin-list3.png" alt="" />
          <img src="/images/coin-list3.png" alt="" />
        </CoinList>
      </CoinBox>
      <BtnBox>
        <ButtonContrainer href={DOMAIN?.UI + "/markets"}>
          <ButtonAmin>{intl.formatMessage({ defaultMessage: "Trade Now" })}</ButtonAmin>
        </ButtonContrainer>
      </BtnBox>
    </Wrapper>
  );
});
export default TradeEverying;
