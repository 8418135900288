import styled from "@emotion/styled";
import { Button, DOMAIN, ExternalLink, useUIKit } from "@oxfun/uikit";
import { memo } from "react";
import { useIntl } from "react-intl";

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
  padding: 34px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("/images/journey-now.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;
const Title = styled.div`
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${({ theme }) => theme.system.gray[9]};
  @media (max-width: 800px) {
    font-size: 28px;
    text-align: center;
  }
`;
const Btn = styled(Button)`
  margin-top: 32px;
  & > a {
    color: ${({ theme }) => theme.system.black};
  }
`;
const JourneyNow = memo(() => {
  const intl = useIntl();
  const { setConnectWalletModal, user } = useUIKit();
  return (
    <Wrapper>
      <Title>{intl.formatMessage({ defaultMessage: "Sign Up in 10 Seconds" })}</Title>
      {!user ? (
        <Btn
          colorScheme="primary"
          onClick={() => {
            setConnectWalletModal?.(true);
          }}
        >
          {intl.formatMessage({ defaultMessage: "Get Started with OX.FUN" })}
        </Btn>
      ) : (
        <Btn colorScheme="primary">
          <ExternalLink href={DOMAIN?.UI + "/markets"}>
            {intl.formatMessage({ defaultMessage: "Start Trading Now" })}
          </ExternalLink>
        </Btn>
      )}
    </Wrapper>
  );
});
export default JourneyNow;
