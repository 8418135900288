import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { getCalcationList, getCoinImg, getMarketsList } from "services/http";
import { useAsyncEffect, useRequest } from "ahooks";
import { HomeProvider } from "./context";
import TradeEverying from "./TradeEverying";
import PopularMarkets from "./PopularMarkets";
import PlayerVault from "./PlayerVault";
import MobileDown from "./MobileDown";
import MultiCollateral from "./MultiCollateral";
import Placeholder from "./Placeholder";
import JourneyNow from "./JourneyNow";

const Wrapper = styled.div`
  overflow: hidden;
`;
const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 1232px) {
    padding: 0 16px;
  }
`;
const IndexPage = () => {
  const intl = useIntl();
  const { data: iconList } = useRequest(getCoinImg);
  const { data: markets } = useRequest(getMarketsList);

  return (
    <HomeProvider value={{ iconList: iconList?.data, markets: markets?.data }}>
      <Wrapper>
        <TradeEverying />
        <PopularMarkets />
        <Container>
          <PlayerVault />
          <MobileDown />
          <MultiCollateral />
          <Placeholder />
          <JourneyNow />
        </Container>
      </Wrapper>
    </HomeProvider>
  );
};

export default IndexPage;
