import styled from "@emotion/styled";
import { memo, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Button, DOMAIN, ExternalLink, Image } from "@oxfun/uikit";
import { useHome } from "./context";
import { useRequest } from "ahooks";
import { getMarketPrice, getMasterTraders } from "services/http";
import BigNumber from "bignumber.js";
import { formatToThousands } from "@oxfun/sdk";
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 96px;
  @media (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 60px;
  }
`;
const WrapperLeft = styled.div`
  flex: 1;
  max-width: 510px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 800px) {
    max-width: 100%;
    align-items: center;
  }
`;
const WrapperLeftTitle = styled.div`
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${({ theme }) => theme.system.gray[9]};
  @media (max-width: 800px) {
    font-size: 28px;
  }
`;
const WrapperLeftDescr = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
  color: ${({ theme }) => theme.system.gray[6]};
  width: 100%;
`;
const WrapperRight = styled.div`
  flex: 1;
  max-width: 540px;
  height: 480px;
  background: url("/images/player-vault.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  @media (max-width: 1232px) {
    max-height: 480px;
  }
  @media (max-width: 1070px) {
    background-size: contain;
  }
  @media (max-width: 800px) {
    min-height: 384px;
    max-width: 100%;
  }
`;
const AprList = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 16px;
  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
  }
`;
const AprItem = styled.div`
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.system.gray[1]};
  background: ${({ theme }) => theme.system.gray[1]};
  flex: 1;
`;
const AprItmeBtc = styled.div`
  display: flex;
  justify-content: space-between;
`;
const AprItemBtcLeft = styled.div`
  & > div:first-of-type {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${({ theme }) => theme.system.green[5]};

    &[data-up] {
      color: ${({ theme }) => theme.system.green[5]};
    }
    &[data-down] {
      color: ${({ theme }) => theme.system.red[5]};
    }
  }
  & > div:last-of-type {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${({ theme }) => theme.system.gray[6]};
  }
`;
const AprItmeTvl = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  padding-top: 4px;
  border-top: 1px solid ${({ theme }) => theme.system.gray[2]};
  & > div:first-of-type {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${({ theme }) => theme.system.gray[6]};
  }
  & > div:last-of-type {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${({ theme }) => theme.system.gray[9]};
  }
`;
// const SkakeNow = styled(ExternalLink)`
//   padding: 12px;
//   border: 1px solid ${({ theme }) => theme.system.blueGray[9]};
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 22px;
//   color: ${({ theme }) => theme.system.gray[9]};
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-top: 32px;
//   width: min-content;
//   white-space: nowrap;
//   cursor: pointer;
//   &:hover {
//     border: 1px solid ${({ theme }) => theme.system.primary[5]};
//     color: ${({ theme }) => theme.system.primary[5]};
//   }
// `;
const SkakeNow = styled(ExternalLink)`
  margin-top: 32px;
`;
const ColBox = styled.div`
  width: 160px;
  height: 160px;
  flex-shrink: 0;
  border-radius: 300px;
  background: #7cfb95;
  filter: blur(130px);
  position: absolute;
  left: 22%;
  top: 40%;
  pointer-events: none;
  z-index: 2;
`;
const PlayerVault = memo(() => {
  const intl = useIntl();
  const { iconList, markets } = useHome();
  const { data } = useRequest(() =>
    getMasterTraders({
      pageNum: 1,
      pageSize: 12,
      searchParams: {
        name: "",
        poolType: "AMM_VAULT"
      },
      searchOrder: {}
    })
  );
  const playList = useMemo(() => {
    const list = data?.data.data || [];
    const envData = list.filter((i) => i.accountId.toString() === "110957" || i.accountId.toString() === "111114");
    if (envData.length) {
      return envData;
    }
    return list?.slice(0, 2);
  }, [data?.data.data]);
  const { data: OxPice } = useRequest(() => getMarketPrice("OX-USDT"));
  const Tvl = (aum: number) => {
    return new BigNumber(aum).multipliedBy(OxPice?.data || 0).toNumber();
  };
  return (
    <Wrapper>
      <WrapperLeft>
        <WrapperLeftTitle>{intl.formatMessage({ defaultMessage: "Player Vault" })}</WrapperLeftTitle>
        <WrapperLeftDescr>
          {intl.formatMessage({
            defaultMessage:
              "We offer BTC/ETH/SOL/OX Player Vault, Buy low and sell high, The daily incentives enable you to compound your returns and widen your range over time. Staking to earn unimaginable APR."
          })}
        </WrapperLeftDescr>
        <AprList>
          {playList.map((i, index) => {
            return (
              <AprItem key={index}>
                <AprItmeBtc>
                  <AprItemBtcLeft>
                    <div
                      data-up={new BigNumber(i.apr || 0).isGreaterThan(0) ? "" : undefined}
                      data-down={new BigNumber(i.apr || 0).isLessThan(0) ? "" : undefined}
                    >
                      {formatToThousands(Number(i.apr) * 100, 0, true) || "--"} %
                    </div>
                    <div>{intl.formatMessage({ defaultMessage: "APR Rewards" })}</div>
                  </AprItemBtcLeft>
                  <Image size={40} circle src={i.avatarUrl || ""} alt="" />
                </AprItmeBtc>
                <AprItmeTvl>
                  <div>{intl.formatMessage({ defaultMessage: "TVL" })}</div>
                  <div>{formatToThousands(Tvl(i.aum || 0), 0)} USD</div>
                </AprItmeTvl>
              </AprItem>
            );
          })}
        </AprList>
        <SkakeNow href={DOMAIN?.UI + "/vaults"}>
          {" "}
          <Button colorScheme="primary">{intl.formatMessage({ defaultMessage: "Stake Now" })}</Button>{" "}
        </SkakeNow>
      </WrapperLeft>
      <WrapperRight>
        <ColBox />
      </WrapperRight>
    </Wrapper>
  );
});
export default PlayerVault;
