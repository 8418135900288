import styled from "@emotion/styled";
import { memo, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useHome } from "./context";
import { Image, Input } from "@oxfun/uikit";
import { useRequest } from "ahooks";
import { getCalcationList } from "services/http";
const Content = styled.div`
  width: 100%;
  margin-bottom: 96px;
  position: relative;
  @media (max-width: 800px) {
    margin-bottom: 60px;
  }
`;
const TableBox = styled.div`
  width: 100%;
  background: linear-gradient(to bottom, rgba(124, 251, 149, 0.6), rgba(124, 251, 149, 0.2), rgba(124, 251, 149, 0.1));
  /* border: 1px solid rgba(124, 251, 149, 0.60); */
  padding: 1px;
  height: 100%;
  margin-top: 48px;
`;
const TableContainer = styled.div`
  z-index: 2;
  background-color: ${({ theme }) => theme.system.blueGray[2]};
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas: "a b c d e";
  padding: 4px;
  gap: 4px;
  position: relative;
  @media (max-width: 990px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "a b c d";
  }
  @media (max-width: 840px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "a b c";
  }
  @media (max-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "a b";
  }
`;
const Item = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.system.blueGray[3]};
  height: max-content;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
const ItemLeft = styled.div`
  display: flex;
  gap: 8px;
  & > span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: ${({ theme }) => theme.system.white};
  }
`;
const ItemRight = styled.div`
  display: flex;
  gap: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  & > span:first-of-type {
    color: ${({ theme }) => theme.system.gray[6]};
  }
  & > span:last-of-type {
    color: ${({ theme }) => theme.system.white};
  }
`;
const Title = styled.div`
  color: ${({ theme }) => theme.system.white};
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  font-weight: 700;
  @media (max-width: 860px) {
    font-size: 56px;
  }
  @media (max-width: 480px) {
    font-size: 28px;
    text-align: center;
  }
`;
const Descr = styled.div`
  margin-top: 16px;
  color: ${({ theme }) => theme.system.white};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;
const Overlay = styled.div`
  height: 240px;
  background: linear-gradient(180deg, rgba(7, 9, 16, 0) 0%, #070910 100%);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;

  &[data-isShow="true"] {
    background: linear-gradient(180deg, rgba(7, 9, 16, 0) 0%, #070910 100%);
    bottom: -10px;
  }
  &[data-isShow="false"] {
    background: none;
    bottom: -30px;
  }
`;
const ShowMore = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: ${({ theme }) => theme.system.primary[5]};
  cursor: pointer;
  &[data-isShow="false"] {
    & > svg {
      transform: rotate(180deg);
    }
  }
`;
const ColBox = styled.div`
  width: 160px;
  height: 160px;
  flex-shrink: 0;
  border-radius: 300px;
  background: #7cfb95;
  filter: blur(130px);
  position: absolute;
  left: 40%;
  top: 20%;
  pointer-events: none;
`;
const MultiColInput = styled(Input)`
  max-width: 556px;
  margin: 0 auto;
  margin-top: 16px;
`;
const MultiCollateral = memo(() => {
  const intl = useIntl();
  const { iconList } = useHome();
  const { data: calcationList } = useRequest(getCalcationList);
  const [isShow, setShow] = useState(true);
  const [searchName, setSearchName] = useState("");
  const searchData = useMemo(() => {
    return calcationList?.data.filter((i) =>
      searchName ? i.instrumentId.includes(searchName.toLocaleUpperCase()) : true
    );
  }, [searchName, calcationList]);
  const data = useMemo(() => {
    if (isShow) {
      return searchData?.slice(0, 50);
    }
    return searchData?.slice(0, 300);
  }, [isShow, calcationList, searchData]);
  return (
    <Content id="MultiCollateral">
      <ColBox />
      <Title>{intl.formatMessage({ defaultMessage: "Deposit MEMEs to Trade Perps" })}</Title>
      <Descr>
        {intl.formatMessage({
          defaultMessage: "Use memes at the best margin rates"
        })}
      </Descr>
      <MultiColInput
        beforeNode={
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path
              d="M13.5233 12.4628L16.7355 15.6743L15.6743 16.7355L12.4628 13.5233C11.2678 14.4812 9.7815 15.0022 8.25 15C4.524 15 1.5 11.976 1.5 8.25C1.5 4.524 4.524 1.5 8.25 1.5C11.976 1.5 15 4.524 15 8.25C15.0022 9.7815 14.4812 11.2678 13.5233 12.4628ZM12.0188 11.9063C12.9706 10.9274 13.5022 9.61532 13.5 8.25C13.5 5.349 11.1503 3 8.25 3C5.349 3 3 5.349 3 8.25C3 11.1503 5.349 13.5 8.25 13.5C9.61532 13.5022 10.9274 12.9706 11.9063 12.0188L12.0188 11.9063Z"
              fill="white"
              fillOpacity="0.35"
            />
          </svg>
        }
        onChange={(e) => {
          setSearchName(e.target.value);
        }}
        placeholder={intl.formatMessage({ defaultMessage: "Search" })}
      />
      <TableBox>
        <TableContainer>
          {data?.map((i, index) => {
            return (
              <Item key={index}>
                <ItemLeft>
                  <Image size={24} src={iconList?.[i.instrumentId]} alt="" />
                  <span>{i.instrumentId === "xOX" ? "OX Credits" : i.instrumentId}</span>
                </ItemLeft>
                <ItemRight>
                  <span>LTV:</span>
                  <span>{i.loanToValue}</span>
                </ItemRight>
              </Item>
            );
          })}
          {(searchData?.length || 0) > 50 ? (
            <Overlay data-isShow={isShow}>
              <ShowMore data-isShow={isShow} onClick={() => setShow(!isShow)}>
                {isShow
                  ? intl.formatMessage({ defaultMessage: "Show More" })
                  : intl.formatMessage({ defaultMessage: "Show Less" })}{" "}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M7.99999 8.78145L11.3 5.48145L12.2427 6.42411L7.99999 10.6668L3.75732 6.42411L4.69999 5.48145L7.99999 8.78145Z"
                    fill="#C8FB7C"
                  />
                </svg>
              </ShowMore>
            </Overlay>
          ) : null}
        </TableContainer>
      </TableBox>
    </Content>
  );
});
export default MultiCollateral;
