import { createContext, useContext } from "react";

type HomeContextTypes = {
  iconList?: { [key: string]: string };
  markets?: IMarket[];
};

const home = createContext<HomeContextTypes>({
  iconList: {},
  markets: []
});
export const HomeProvider = home.Provider;
export const useHome = () => useContext(home);
