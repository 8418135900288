import styled from "@emotion/styled";
import { NextPage } from "next";
import React from "react";
import { NextSeo } from "next-seo";
import { useIntl } from "react-intl";
import IndexPage from "widgets/Homepage";
type TProps = {
  pageProps: {
    data?: IHomePage;
  };
};
const Index: NextPage<TProps> = ({ pageProps }) => {
  const intl = useIntl();

  return (
    <React.Fragment>
      <NextSeo
        title={intl.formatMessage({ defaultMessage: "OX.FUN | NextGen perps trading" })}
        description={intl.formatMessage({
          defaultMessage: "Trade MEME Coins on OXFUN Now."
        })}
      />
      <IndexPage />
    </React.Fragment>
  );
};

export default Index;
