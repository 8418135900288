import styled from "@emotion/styled";
import { memo } from "react";
import { useIntl } from "react-intl";
import { useHome } from "./context";
import PlaceHoder1 from "assets/svgs/home-placeholder1.svg";
import PlaceHoder2 from "assets/svgs/home-placeholder2.svg";
import PlaceHoder3 from "assets/svgs/home-placeholder3.svg";
import PlaceHoder4 from "assets/svgs/home-placeholder4.svg";
const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 96px;
  @media (max-width: 800px) {
    margin-bottom: 60px;
  }
`;
const Title = styled.div`
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${({ theme }) => theme.system.gray[9]};
  text-align: center;
  @media (max-width: 600px) {
    font-size: 28px;
  }
`;
const ItemList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "a b";
  gap: 24px;
  margin-top: 48px;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template-areas: "a";
  }
`;
const Item = styled.div`
  padding-left: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  border: 1px solid ${({ theme }) => theme.system.gray[2]};
  background: rgba(7, 9, 16, 0.2);
  backdrop-filter: blur(15px);
  & > svg {
    min-width: 240px;
  }
  @media (max-width: 600px) {
    padding: 16px;
    flex-direction: column-reverse;
  }
`;
const ItemBox = styled.div`
  max-width: 300px;
`;
const ItemTitle = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.system.gray[9]};
`;
const ItemDesc = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
  color: ${({ theme }) => theme.system.gray[6]};
`;

const Placeholder = memo(() => {
  const intl = useIntl();
  const { iconList, markets } = useHome();
  const data = [
    {
      title: intl.formatMessage({ defaultMessage: "Trade Memes Without Selling" }),
      descr: intl.formatMessage({
        defaultMessage: "Use your memes as collateral to trade perps, keeping ownership while gaining market exposure."
      }),
      img: <PlaceHoder1 />
    },
    {
      title: intl.formatMessage({ defaultMessage: "Exclusive Meme Perp" }),
      descr: intl.formatMessage({
        defaultMessage: "Trade the largest selection of meme perps, featuring many exclusive, first-to-market assets."
      }),
      img: <PlaceHoder2 />
    },
    {
      title: intl.formatMessage({ defaultMessage: "Stake for Rewards" }),
      descr: intl.formatMessage({
        defaultMessage: "Boost your earnings by staking into high-reward strategies tailored for all experience levels."
      }),
      img: <PlaceHoder3 />
    },
    {
      title: intl.formatMessage({ defaultMessage: "Get alpha on the Degen Feed" }),
      descr: intl.formatMessage({
        defaultMessage: "Get real-time alpha and insider tips from the Degen Feed to stay ahead in the meme markets."
      }),
      img: <PlaceHoder4 />
    }
  ];
  return (
    <Wrapper>
      <Title>{intl.formatMessage({ defaultMessage: "OXFUN Exclusive Offerings" })}</Title>
      <ItemList>
        {data.map((i) => {
          return (
            <Item key={i.title}>
              <ItemBox>
                <ItemTitle>{i.title}</ItemTitle>
                <ItemDesc>{i.descr}</ItemDesc>
              </ItemBox>
              {i.img}
            </Item>
          );
        })}
      </ItemList>
    </Wrapper>
  );
});
export default Placeholder;
