import styled from "@emotion/styled";
import { memo } from "react";
import { useIntl } from "react-intl";
import { useHome } from "./context";
import { QRCodeSVG } from "qrcode.react";
import { DOMAIN, ExternalLink } from "@oxfun/uikit";
const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 96px;
  display: flex;
  border: 1px solid ${({ theme }) => theme.system.gray[2]};
  position: relative;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
  }
`;
const WrapperLeft = styled.div`
  background: url("/images/mobile-down.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 440px;
  max-width: 640px;
  flex: 1;
  position: relative;
  z-index: 4;
  @media (max-width: 1150px) {
    background-size: contain;
    max-height: 365px;
  }
  @media (max-width: 1100px) {
    max-height: 300px;
  }
  @media (max-width: 950px) {
    background-size: cover;
  }
  @media (max-width: 800px) {
    min-height: 266px;
    background-size: contain;
    width: 100%;
    background-position-y: top;
  }
`;
const WrapperRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 24px 0;
  @media (max-width: 800px) {
    padding: 16px;
  }
`;
const WrapperTitle = styled.div`
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${({ theme }) => theme.system.gray[9]};
  @media (max-width: 600px) {
    font-size: 28px;
  }
`;
const QrcodeBox = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 40px;
`;
const QrcodeContent = styled.div``;
const QrcodeTitle = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.system.gray[9]};
`;
const QrcodeItem = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.system.gray[6]};
`;
const DownApp = styled(ExternalLink)`
  border: 1px solid ${({ theme }) => theme.system.blueGray[9]};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  color: ${({ theme }) => theme.system.gray[9]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  width: min-content;
  white-space: nowrap;
  padding: 12px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${({ theme }) => theme.system.primary[5]};
    color: ${({ theme }) => theme.system.primary[5]};
  }
  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;
const QrcodeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: ${({ theme }) => theme.system.white};
`;
const ColBox = styled.div`
  width: 160px;
  height: 160px;
  flex-shrink: 0;
  border-radius: 300px;
  background: #7cfb95;
  filter: blur(130px);
  position: absolute;
  left: 18%;
  top: -10%;
  pointer-events: none;
  z-index: 2;
`;
const Descr = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.system.gray[9]};
  margin-top: 8px;
`;
const MobileDown = memo(() => {
  const intl = useIntl();
  const imageSettings = {
    src: "/images/qrcode.png",
    x: 47,
    y: 41,
    height: 20,
    width: 20,
    excavate: true
  };
  return (
    <Wrapper>
      <ColBox />
      <WrapperLeft></WrapperLeft>
      <WrapperRight>
        <WrapperTitle>{intl.formatMessage({ defaultMessage: "Trade Anytime, Anywhere" })}</WrapperTitle>
        <Descr>{intl.formatMessage({ defaultMessage: "Sign up in 10 seconds Now" })}</Descr>

        <QrcodeBox>
          <QrcodeContainer>
            <QRCodeSVG
              value={DOMAIN?.UI + "/mobile-redirect"}
              imageSettings={imageSettings}
              size={110}
              bgColor="white"
              fgColor="black"
            />
          </QrcodeContainer>

          <QrcodeContent>
            <QrcodeTitle>{intl.formatMessage({ defaultMessage: "Scan QR Code to Download App" })}</QrcodeTitle>
            <QrcodeItem>
              <div>{intl.formatMessage({ defaultMessage: "Android " })}</div>
              <div>/</div>
              <div>{intl.formatMessage({ defaultMessage: "iOS" })}</div>
            </QrcodeItem>
            <DownApp href={DOMAIN?.UI + "/mobile-redirect"} target="_self">
              {intl.formatMessage({ defaultMessage: "Download App" })}
            </DownApp>
          </QrcodeContent>
        </QrcodeBox>
      </WrapperRight>
    </Wrapper>
  );
});
export default MobileDown;
